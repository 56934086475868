// src/stores/counter.js
import { defineStore } from 'pinia';

export const useCounterStore = defineStore('counter', {
  // 定义 state，类似于组件中的 data
  state: () => ({
    downloadId:'1857369095134121984',
    publicVapidKey : 'BOx_efK9Ks_THE9YJSx0CwI4NXYl-3TPHxaHd0dC9s-vfU0AuXISPQGhl92OZIKdkZRItstcjHK1pi9w1H3VOTo',
    downloadUrl : '/prod-api',
    type:'facebook',
    pixelCode:'1201674067761049',
    model:'模板一',
    appurl:'https://win92vip.com/',
  }),
});
