// import { useCounterStore } from '@/stores/index';
// const counter = useCounterStore();
// utils/pixel.js
export function loadPixel(platform) {
    switch (platform) {
      case 'facebook':
        console.log('facebook');
        
        // Facebook Pixel 初始化代码
        !function(f,b,e,v,n,t,s){
          if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)
        }(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', 'counter.currentPlatform');
        fbq('track', 'PageView');
        break;
      
      case 'tiktok':
        // TikTok Pixel 初始化代码
        !function (w, d, t) {
          w.TiktokAnalyticsObject = t;
          var ttq = w[t] = w[t] || [];
          ttq.methods = ["page", "track"];
          ttq.setAndDefer = function (t, e) {
            t[e] = function () {
              t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          };
          for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
          ttq.load = function (e) {
            var a = document.createElement("script");
            a.type = "text/javascript";
            a.async = !0;
            a.src = "https://analytics.tiktok.com/i18n/pixel/events.js?sdkid=" + e;
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(a, s);
          };
          ttq.load('YOUR_TIKTOK_PIXEL_ID');
          ttq.page();
        }(window, document, 'ttq');
        break;
      
      case 'kwai':
        // Kwai Pixel 初始化代码
        !function(k,s,a,i) {
          k.ksDataLayer = k.ksDataLayer || [];
          k[a] = function() {
            k.ksDataLayer.push(arguments);
          };
          k[a]('js', new Date());
          var t = s.createElement('script');
          t.async = true;
          t.src = 'https://kwai-track.oss-cn-hangzhou.aliyuncs.com/ks-pixel/main.min.js';
          var e = s.getElementsByTagName('script')[0];
          e.parentNode.insertBefore(t, e);
        }(window, document, 'ksPixel');
        ksPixel('init', 'YOUR_KWAI_PIXEL_ID');
        ksPixel('track', 'PageView');
        break;
  
      default:
        console.warn('Unknown platform');
    }
  }
  