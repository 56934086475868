import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import naive from 'naive-ui'; // 引入Naive UI 
import { createPinia } from 'pinia';
import './registerServiceWorker'
import { useCounterStore } from '@/stores/index';
import { loadPixel } from './utils/pixel';

const app = createApp(App);
const pinia = createPinia();

app.use(router); // 使用路由
app.use(naive);
app.use(pinia);
const counter = useCounterStore();
// 根据 store 的初始值加载像素
switch (counter.type) {
    case 'facebook':
    //   console.log('facebook',counter.currentPlatform);
      
      // Facebook Pixel 初始化代码
      !function(f,b,e,v,n,t,s){
        if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)
      }(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', counter.pixelCode);
      fbq('track', 'PageView');
      break;
    
    case 'tiktok':
      // TikTok Pixel 初始化代码
      !function (w, d, t) {
        w.TiktokAnalyticsObject = t;
        var ttq = w[t] = w[t] || [];
        ttq.methods = ["page", "track"];
        ttq.setAndDefer = function (t, e) {
          t[e] = function () {
            t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        };
        for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
        ttq.load = function (e) {
          var a = document.createElement("script");
          a.type = "text/javascript";
          a.async = !0;
          a.src = "https://analytics.tiktok.com/i18n/pixel/events.js?sdkid=" + e;
          var s = document.getElementsByTagName("script")[0];
          s.parentNode.insertBefore(a, s);
        };
        ttq.load(counter.pixelCode);//自己的TikTok Pixel
        ttq.page();
      }(window, document, 'ttq');
      break;
    
    case 'kwai':
      // Kwai Pixel 初始化代码
      !function(k,s,a,i) {
        k.ksDataLayer = k.ksDataLayer || [];
        k[a] = function() {
          k.ksDataLayer.push(arguments);
        };
        k[a]('js', new Date());
        var t = s.createElement('script');
        t.async = true;
        t.src = 'https://kwai-track.oss-cn-hangzhou.aliyuncs.com/ks-pixel/main.min.js';
        var e = s.getElementsByTagName('script')[0];
        e.parentNode.insertBefore(t, e);
      }(window, document, 'ksPixel');
      ksPixel('init', counter.pixelCode);//自己的Kwai Pixel
      ksPixel('track', 'PageView');
      break;

    default:
      console.warn('Unknown platform');
  }
loadPixel(counter.pixelCode);

app.mount('#app');

let downloadstates = false; // 安装状态  
function checkdownload(installButton,playButton) {
    // 模拟安装过程
    
    let progress = 0;
    let installInterval = setInterval(function () {
      progress += 1;
      installButton.innerHTML = `installation progress: ${progress}%`;
    
      if (progress >= 101) {
          alert(`The installation was successful. Please open the application on the desktop. If the application is not found on the desktop, please check whether the device has the browser's "Add Desktop Shortcuts" permission enabled, or a Google Play application is installed.`)
          downloadstates = false;
        clearInterval(installInterval);
        installButton.textContent = 'installed';
        installButton.classList.remove('loading');
        installButton.style.display = 'none';
   playButton.style.display = 'block';
        deferredPrompt = null;
        // 检查是否在 PWA 环境中
           if (window.matchMedia('(display-mode: standalone)').matches) {
               
               window.location.href = '/'; // 重定向到首页
               return; // 阻止后续下载逻辑的执行
           }else{
             
         }
      }
    }, 100); // 每500ms更新一次进度
}

// 安装
let deferredPrompt;
   // 绑定并监听下载事件  			
   window.addEventListener('beforeinstallprompt', (e) => { 
    
    
    const installButton = document.getElementById('install-btn');
    const playButton = document.getElementById('play-btn');
   
     deferredPrompt = e;
     e.preventDefault();
     console.log(document.getElementById('install-btn'));
     
    
     installButton.style.display = 'block';
   installButton.innerText = "Install";
   playButton.style.display = 'none';

 // 下载安装事件
   installButton.addEventListener('click', async () => {
    
    document.getElementById('modal').style.visibility='hidden';
    console.log(123,document.getElementById('modal').style.display);
    
       Notification.requestPermission().then(function (result) {
           console.log(result);
           if (result === "granted") {
               console.log('agreed');
           }
         });
       // 节流
         if(downloadstates||deferredPrompt==false){
             return false
         }
         // 触发下载
       deferredPrompt.prompt();
       deferredPrompt.userChoice.then((choiceResult) => {
         if (choiceResult.outcome === 'accepted') {
             downloadstates=true
             installButton.innerText = "Installation...";
              installButton.classList.add('loading'); // 假设你有一个 .loading 的 CSS 类
             // 可选：添加加载动画，比如旋转图标
            
             checkdownload(installButton,playButton)
             // 下载请求
             fetch(counter.downloadUrl+'/link/addRequestCount/'+counter.downloadId,{method: 'POST'})
             .then(response => response.json())
                 .then(data => {})
                 .catch(error => console.error('Error:', error));
          
         } else {
           console.log('User dismissed the install prompt');
         }
         
       });
   });
   subscribeUser()
   // 调用函数检查订阅状态
   checkSubscription().then(res => {
       if (res) {
           // 已订阅，进行相应处理
             
       } else {
            // 未订阅，提示用户订阅推送
               downloadstates=false
               alert('Download and install requires agreeing to receive messages pushed by the application')
               subscribeUser()
       }
   });
   
   });
        // 工具函数：将Base64字符串转换为Uint8Array
function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');
      
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
      
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

      // 订阅用户
    //   const downloadUrl = 'https://test.nikozhou.com:82/prod-api'; // 下载地址（服务器IP地址）  
    //   const downloadId = '1837097112395976704'; // 参数ID 
    //   const publicVapidKey = 'BMhnlmnCVw3ZYrTyU_0pfTAlzqUVepf2OgUnHX_V8TmyicaQ_7112xVh_vdAjOCdvlqPcn78_gXzQswWK74NHp8';
      async function subscribeUser() {
        const applicationServerKey = urlBase64ToUint8Array(counter.publicVapidKey);
          // 注册Service Worker
          const register = await navigator.serviceWorker.ready;
        
              // 订阅用户并获取Push订阅信息
              const subscription = await register.pushManager.subscribe({
                  userVisibleOnly: true,
                    applicationServerKey: applicationServerKey
              });
              let data={
                  subscription:subscription,
                  applicationId:counter.downloadId
              }
              // 将订阅信息发送到服务器保存
              await fetch(counter.downloadUrl+'/pwa/notification/subscribe', {
                  method: 'POST',
                  body: JSON.stringify(data),
                  headers: {
                      'Content-Type': 'application/json'
                  }
              });
    }

       
// }

   
   // 检查订阅状态
 async function checkSubscription() {
     // 确保 Service Worker 已准备就绪
     const registration = await navigator.serviceWorker.ready;
 
     // 获取当前的订阅对象
     const subscription = await registration.pushManager.getSubscription();
 
     if (subscription) {
         // 用户已订阅推送通知
         console.log('用户已订阅:', subscription);
         return true;
     } else {
         // 用户未订阅推送通知
         console.log('用户未订阅');
         return false;
     }
 }
 
