<template>
  <div>
    <!-- Your component HTML goes here -->
  </div>
</template>

<script setup>
// Composition API setup
import { onMounted } from 'vue';
import { useCounterStore } from '@/stores/index';
onMounted(() => {  
    const counter = useCounterStore();
  window.location.href = counter.appurl;  
});  
</script>
