<template>
   <div>
    <component :is="currentPage"></component>  <!-- 动态加载组件 -->
   </div>
   
  </template>
  
  <script>
 import Page1 from './Page1.vue'; // 示例组件
import Page2 from './Page2.vue'; // 示例组件
import Page3 from './Page3.vue'; // 示例组件
import Page4 from './Page4.vue'; // 示例组件
import { useCounterStore } from '@/stores/index';
  
  export default {
    data() {
      return {
        currentPage: Page1,  // 默认为 Home 页面
      };
    },
    created() {
        const counter = useCounterStore();
        if(counter.model=='模版一'){
            this.currentPage=Page1
        }else if(counter.model=='模版二'){
            this.currentPage=Page2
        }else if(counter.model=='模版三'){
            this.currentPage=Page3
        }else{
            this.currentPage=Page4
        }
  },
  };
  </script>